import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';

const trackingId = "GTM-PG8XMKG"; // Replace with your Google Analytics tracking IDe
ReactGA.initialize(trackingId);

const tagManagerArgs = {
  gtmId: "GTM-PG8XMKG"
}
TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
